import React from "react";
import { generateLinkComponent } from "tuteria-frontend-components/src/simple/Button";
import { ClientLandingPage } from "tuteria-frontend-components/src/pages/ExternalPages/ClientRequestLandingPage";
import { Link } from "react-router-dom";

const SecondaryLinkButton = generateLinkComponent(Link, "secondary");
const video_link =
  process.env.video_link ||
  "https://www.youtube.com/embed/mla_SMQoFeU?rel=0&amp;controls=0&amp;modestbranding=1&amp;fs=0&amp;disablekb=1&amp;showinfo=0";
export const ParentLandingPage = props => (
  <ClientLandingPage
    videoLink={video_link}
    {...props}
    linkRender={(text, props = { big: true }) => (
      <SecondaryLinkButton
        css={`
          @media (max-width: 768px) {
            padding: 12px 24px;
          }
        `}
        {...props}
        to="/s/hometutoring/about-child"
      >
        {text}
      </SecondaryLinkButton>
    )}
  />
);

export default ParentLandingPage;
